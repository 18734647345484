import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/:catchAll(.*)/",
    name: "error404",
    component: () => import("../views/Error404.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/services/:id",
    name: "Services",
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: "landing",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: "landing",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/app",
    name: "app",
    meta: "app",
    component: () => import("../views/Dashboard"),
    children: [
      {
        path: "/app",
        name: "app",
        component: () => import("@/views/Dashboard/app.vue"),
      },
      {
        path: "programs",
        name: "programs",
        meta: "app",
        component: () => import("@/views/Dashboard/programs.vue"),
      },
      {
        path: "reports",
        name: "reports",
        meta: "app",
        component: () => import("@/views/Dashboard/reports.vue"),
      },
      {
        path: ":id",
        name: "program-detail",
        meta: "app",
        component: () => import("@/views/Dashboard/program-detail.vue"),
        children: [
          {
            path: "",
            name: "program-detail",
            meta: "app",
            component: () =>
              import("@/views/Dashboard/program-detail-content.vue"),
          },
          {
            path: "/app/:id/report",
            name: "program-detail-report",
            meta: "app",
            component: () => import("@/views/Dashboard/report.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/courses/:id",
    name: "courses",
    component: () => import("@/views/Courses.vue"),
  },
  {
    path: "/jama-bugbounty",
    name: "jama-bugbounty",
    component: () => import("@/views/SingUpOptions.vue"),
  },
  {
    path: "/singup",
    name: "singup",
    component: () => import("@/views/LoginView.vue"),
    children: [
      {
        path: "company",
        name: "singup-company",
        component: () => import("@/views/SingupCompany.vue"),
      },
      {
        path: "investigator",
        name: "singup-investigator",
        component: () => import("@/views/SingUpInvestigator.vue"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/new-password",
        name: "new-password",
        component: () => import("@/views/NewPassword.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  mode: "history",
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
