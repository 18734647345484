<template>
  <div class="home">
    <div class="home-landing">
      <Landingcards />
    </div>
    <div class="home-service">
      <Services />
      <div class="course-container">
        <Courses />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Landingcards from "@/components/landingcards.vue";
import Services from "@/components/services.vue";
import Courses from "../components/courses.vue";

export default {
  name: "Home",
  components: {
    Landingcards,
    Services,
    Courses,
  },
};
</script>

<style lang="scss">
.home {
  .home-landing {
    background: linear-gradient(150deg, #00a2f8 0%, #0c2bae 49%, #0a103b 100%);
  }
  .home-service {
    background: linear-gradient(180deg, #f9f9ff 0%, #f9f9ff 47%, #efeff2 100%);
    .course-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 200px;
    }
  }
}
</style>
