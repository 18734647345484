<template>
  <div class="bug-bounty">
    <div class="header">
      <h1>Bug Bounty</h1>
      <p>¿Qué es y como ayuda el <span>bug bounty</span> a tu empresa?</p>
    </div>
    <div class="content">
      <img src="../assets/img/pc.png" alt="" />
      <div class="text">
        <p>
          Con <span>Jama Security</span> abrirás las posibilidades de tu empresa
          para que los entusiastas de seguridad informática puedan encontrar
          vulnerabilidades que ningún scanner automático podrá encontrar.
          <br /><br />
          Un bug bounty program o programa de recompensas de errores se trata de
          un acuerdo que ofrecen numerosas organizaciones, compañías, sitios web
          y desarrolladores de software para recompensar (tanto monetarias como
          no) a los individuos que reporten errores, vulnerabilidades y fallos
          de seguridad. <br /><br />
          <strong
            >Te gustaría participar como colaborador o como empresa, regístrate </strong
          >
        </p>
        <div class="buttons">
          <Calltoaction
            :whereto="'jama-bugbounty'"
            @click="showAlert"
            :style="'primary'"
            :text="'Regístrate '"
          />
          <Calltoaction
            :whereto="'/services/bugbounty'"
            @click="showAlert"
            :style="'borded'"
            :text="'Conoce más'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calltoaction from "./calltoaction.vue";
export default {
  components: { Calltoaction },
};
</script>

<style lang="scss">
.bug-bounty {
  width: 100%;
  padding: 120px 0 150px 0;
  .header {
    text-align: center;
    color: white;
    h1 {
      font-size: 40px;
      font-weight: 800;
      margin: 0;
    }
    p {
      margin: 25px 0 0 0;
      text-transform: uppercase;
      font-size: 12px;
      span {
        color: #0eebe1;
      }
    }
  }
  .content {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 460px;
    }
    .text {
      margin-left: 45px;
      color: white;
      width: 650px;
      p {
        font-size: 15px;
        span {
          color: #0eebe1;
        }
      }
      .buttons {
        margin-top: 55px;
        display: flex;
        .primary {
          margin-right: 30px;
        }
      }
    }
  }
  @media (max-width: 1280px) {
    .content {
      padding: 0 60px;
      flex-direction: column;
      align-items: flex-end;
      img {
        width: 100%;
      }
      .text {
        width: 100%;
      }
      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
