import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/style/app.scss";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "primeflex/primeflex.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";

createApp(App)
  .use(PrimeVue, { ripple: true })
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("InputText", InputText)
  .component("MultiSelect", MultiSelect)
  .component("Calendar", Calendar)
  .component("Dropdown", Dropdown)
  .component("ProgressBar", ProgressBar)
  .component("Button", Button)
  .use(store)
  .use(VueSweetalert2)
  .use(router)
  .mount("#app");
