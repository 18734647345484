<template>
  <div class="footer">
    <vue-element-loading
      :active="isActive"
      spinner="bar-fade-scale"
      :is-full-screen="true"
      color="#222ac3"
      size="70"
    />
    <div class="suscribe" v-if="$route.name == 'Home'">
      <div class="content">
        <div class="text">
          <h1>Suscríbete</h1>
          <span
            >Te mantendremos informado de las últimas noticias en seguridad
            informática.</span
          >
        </div>
        <form @submit.prevent="suscribe" class="suscribe-form">
          <input
            ref="suscribe"
            type="text"
            name="suscribe-email"
            placeholder="Correo electrónico"
            v-model="suscribeEmail"
          />
          <button type="submit">Envíar</button>
        </form>
      </div>
    </div>
    <div class="social-media">
      <div class="content">
        <div class="icons">
          <a target="_blank" href=""
            ><img src="../assets/img/facebook-logo.svg" alt=""
          /></a>
          <a target="_blank" href=""
            ><img src="../assets/img/twitter.svg" alt=""
          /></a>
          <a target="_blank" href=""
            ><img src="../assets/img/linkedin-logo.svg" alt=""
          /></a>
        </div>
        <div class="terms">
          <div class="created-by">
            <a>
              JAMA Security ®
            </a>
          </div>
          <div class="terms-conditions">
            <a href="">Términos y condiciones</a>
            <span>|</span>
            <a href="">Política de privacidad</a>
            <span>|</span>
            <router-link :to="{ path: '/contact' }">Contacto</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  name: "footer",
  data() {
    return {
      v$: useVuelidate(),
      suscribeEmail: "",
      isActive: false,
    };
  },
  components: {
    VueElementLoading,
  },
  methods: {
    suscribe() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.isActive = true;
        axios
          .post("https://formspree.io/f/xgerlavw", {
            body: {
              suscribeEmail: this.suscribeEmail,
            },
            headers: {
              Accept: "application/json",
            },
          })
          .then(() => {
            this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Tu correo se ah enviado con exito",
              showConfirmButton: false,
              timer: 3000,
            });
            this.isActive = false;
          })
          .catch(() => {
            this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: "Por favor ingresa un correo valido",
              showConfirmButton: false,
              timer: 3000,
            });
            this.isActive = false;
          });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Por favor ingresa un correo valido",
          showConfirmButton: false,
          timer: 3000,
        });
        this.isActive = false;
      }
    },
  },
  validations() {
    return {
      suscribeEmail: { required, email }, // Matches this.contact.email
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  height: 300px;
  position: relative;
  .suscribe {
    position: relative;
    height: 100%;
    .content {
      @media (max-width: 1280px) {
        position: relative;
        bottom: 20%;
        right: 0;
        width: 80%;
        margin: 0 auto;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        .text {
          h1 {
            margin: 0;
          }
          span {
            display: none;
          }
        }
      }
      height: 180px;
      border-radius: 6px;
      background-color: #222ac3;
      position: absolute;
      bottom: 70%;
      right: 30%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 760px;
      padding: 0 40px;
      .text {
        width: 330px;
        color: white;
        h1 {
          margin: 0 0 20px 0;
          font-size: 24px;
        }
        span {
          font-size: 15px;
        }
      }
      .suscribe-form {
        position: relative;
        display: flex;
        flex-direction: column;
        input {
          border: 4px solid #ffffff;
          height: 55px;
          border-radius: 40px;
          width: 327px;
          padding: 0 95px 0 25px;
          &::placeholder {
            color: rgba(124, 124, 124, 0.5);
            font-size: 15px;
          }
          @media (max-width: 1280px) {
            width: 100%;
          }
        }
        span {
          color: red;
        }
        button {
          position: absolute;
          background-color: rgba(34, 42, 195, 1);
          border: none;
          border-radius: 40px;
          padding: 18px 25px;
          color: white;
          right: 2px;
          top: 2px;
          cursor: pointer;
        }
      }
    }
  }
  .social-media {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      max-width: 1280px;
      padding: 0 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      flex-direction: column;
      .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 50%;
        a {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          border: 2px solid rgba(156, 156, 168, 1);
          margin: 0 10px;
        }
      }
    }
  }
  .terms {
    @media (max-width: 1280px) {
      display: none;
    }
    min-width: 1280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    color: rgba(103, 103, 119, 1);
    position: absolute;
    bottom: 30px;
    .created-by {
      a {
        color: #676777;
      }
    }
    .terms-conditions {
      a {
        color: rgba(103, 103, 119, 1);
      }
      span {
        margin: 0 15px;
      }
    }
  }
}
</style>
