<template>
  <div class="landingcards">
    <Textimage />
    <BugBounty />
  </div>
</template>

<script>
import Textimage from "@/components/text-image.vue";
import BugBounty from './bugBounty.vue';
export default {
  name: "Landingcards",
  components: {
    Textimage,
    BugBounty
  },
};
</script>

<style lang="scss">
.landingcards {
  max-width: 1280px;
  margin: auto;
}
</style>
