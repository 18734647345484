<template>
  <div class="text-image">
    <div class="text">
      <h1>Get Hacked <span> before </span> you get hacked.</h1>
      <p>
        La plataforma de bug bounty hacking ético de Jama Security lo ayudan a
        prevenir violaciones de seguridad de TI.
      </p>
      <Calltoaction :whereto="'/services/bugbounty'" :style="'calltoaction-openvideo'" :text="'Conocer más'" />
    </div>
    <div class="image">
      <img src="../assets/img/hacker.png" alt="" />
    </div>
    <div class="footer">
      <span class="jama"><strong>Jama Security ©</strong> 2021</span>
      <span class="scroll-down"
        ><i class="flaticon-up-arrow"></i>Scroll down</span
      >
    </div>
  </div>
</template>

<script>
import Calltoaction from "./calltoaction.vue";
export default {
  components: {
    Calltoaction,
  },
};
</script>

<style lang="scss">
.text-image {
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .image {
    width: 570px;
  }
  .text {
    width: 470px;
    h1 {
      font-size: 60px;
      color: white;
      span {
        color: #0eebe1;
      }
    }
    p {
      color: #0eebe1;
      margin-bottom: 20px;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    span {
      color: white;
      font-size: 12px;
    }
    .jama {
      position: relative;
      float: right;
    }
    .scroll-down {
      position: absolute;
      right: 50%;
      animation: ca3_fade_move_down 2s ease-in-out infinite;
      i {
        position: absolute;
        top: 1px;
        right: 75px;
        transform: rotate(270deg);
      }
    }
  }
  @keyframes ca3_fade_move_down {
    0% {
      transform: translate(0, -20px) rotate(-90deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(0, 20px) rotate(-90deg);
      opacity: 0;
    }
  }
  @media (max-width: 1280px) {
    padding: 0 60px;
    width: 100%;
    flex-direction: column;
    .image{
      text-align: center;
      width: 100%;
      margin: 20px 0;
      img{
        width: 100%;
      }
    }
    .text{
      width: 100%;
      h1{
        font-size: 40px;
      }
    }
    .footer{
      position: relative;
      .scroll-down{
        display: none;
      }
    }
  }
}
</style>
