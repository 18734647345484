<template>
  <div class="dashboard">
    <Header v-if="$route.matched[0].meta !== 'app'" />
    <div class="main">
      <router-view />
    </div>
    <Footer v-if="$route.matched[0].meta !== 'app'" />
  </div>
</template>

<script>
import Footer from "./components/footer.vue";
import Header from "./components/header.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
}
.dashboard {
  .main {
    background-color: white;
  }
}
</style>
