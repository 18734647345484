<template>
  <div class="calltoaction">
    <router-link :to="{ path: `${whereto}` }" :class="style"
      >{{ text }}<i class="icon" v-if="icon === true" :class="iconName"></i
    ></router-link>
  </div>
</template>

<script>
export default {
  name: "CallToAction",
  data() {
    return {
      calltoactionOptions: false,
    };
  },
  props: {
    text: {
      type: String,
      required: true,
      default: "",
    },
    style: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    whereto: {
      type: String,
      required: false,
      default: "",
    },
    iconName: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.calltoaction {
  position: relative;
}
.icon {
  position: relative;
  transform: rotate(90deg);
  font-size: 10px;
  margin-left: 10px;
}
a {
  cursor: pointer;
  color: white;
  border-radius: 5px;
  padding: 15px 30px;
  text-align: center;
}
.calltoaction-gradient {
  background: linear-gradient(
    300deg,
    #111cf0 0%,
    #101def 0%,
    #1054eb 15%,
    #0f84e8 30%,
    #0eabe5 45%,
    #0ec9e3 59%,
    #0edee2 74%,
    #0eebe1 87%,
    #0ef0e1 100%
  );
  box-shadow: 0px 4px 16px #0334d929;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.calltoaction-openvideo {
  background-color: #222ac3;
  padding: 15px 35px;
  box-shadow: 0px 6px 16px rgba(0, 7, 54, 0.2);
  width: 155px;
  i {
    margin-left: 10px;
    &::before {
      font-size: 12px;
    }
  }
}
.borded {
  background-color: transparent;
  border: 1px solid #0eebe1;
  padding: 15px 30px;
  border-radius: 5px;
  font-weight: 600;
}
.primary {
  box-shadow: 0px 6px 16px #00073633;
  background-color: #0eebe1f2;
  border-radius: 5px;
  padding: 15px 30px;
  font-weight: 600;
}
</style>
