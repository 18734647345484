import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    content: {
      bugbounty: {
        firstword: "You are an essential ingredient in our ongoing",
        keyword: "effort to reduce",
        secondword: "Security Risk",
        subtitle:
          "Con la plataforma de Bug Bounty ayudamos a conectar empresas con entusiastas de seguridad informatica.",
        detailTitle: "Bug Bounty.",
        detailText:
          "Un Bug Bounty es una recompensa que una empresa ofrece a todos aquellos que encuentran vulnerabilidades en un ámbito determinado y autorizado. Ese ámbito puede ser un sitio web, una aplicación, una API, etc. Depende de la empresa determinar qué servicios podrá explorar la gente en busca de vulnerabilidades de seguridad. Hay algunas reglas que respetar y cada Bug Bounty debe establecer claramente los límites que los hackers no deben sobrepasar.",
        slides: [
          {
            title: "Aplicaciones en producción",
            text:
              "Después de hacer una evaluación de vulnerabilidades antes de que la aplicación o el sistema salga a estado de producción los investigadores de seguridad informática encontraran vulnerabilidades en las empresas que estén colaborando con nosotros.",
          },
          {
            title: "Flexibilidad de pruebas.",
            text:
              "La recompensa por errores también proporciona la flexibilidad que muchas marcas necesitan para satisfacer sus necesidades de prueba y, al mismo tiempo, mantienen varios proyectos digitales a tiempo y sin agotar los recursos.",
          },
          {
            title: "Económico.",
            text:
              "Un buen consultor puede cobrar entre $ 100 y $ 500 por hora y algunos cobrarán incluso más que eso. Pero los programas de recompensas por errores pueden ser significativamente más baratos. Algunos programas de recompensas por errores son gratuitos y solo recompensan los puntos de clasificación, lo que mejora la clasificación de los investigadores en el sitio web de las plataformas de alojamiento.",
          },
        ],
        footer:
          "Si estas interesado en iniciar un programa de recompensas, contáctanos",
      },
      pentesting: {
        firstword: "Technology trust is a good thing,",
        keyword: "but control",
        secondword: "is a better one.",
        subtitle:
          "Con el servicio de Pentesting te ayudaremos a encontrar y prevenir fallos en tus sistemas.",
        detailTitle: "Pentesting.",
        detailText:
          "El Pentesting o también llamado test de penetración está diseñado para determinar el alcance de los fallos de seguridad de un sistema. Asimismo, es una de las prácticas más demandadas actualmente ya que gracias a estos test, una empresa puede llegar a saber a qué peligros está expuesta y cuál es el nivel de eficiencia de sus defensas. Por eso, el “Pentester” o Auditor de ciberseguridad es una de las profesiones más demandas dentro del mundo de la Seguridad Informática, lo que los convierte en uno de los profesionales más solicitados del momento, al igual que las escuelas donde se imparte esta especialización.",
        slides: [
          {
            title: "Pentesting de Caja Blanca",
            text:
              "En este caso, el Pentester o Auditor conoce todos los datos sobre el sistema: Estructura, contraseñas, IPs, firewalls… Y suele formar parte del equipo técnico de la empresa. Es el más completo y forma parte de un análisis integral de la estructura. Gracias a toda esta información preliminar es relativamente fácil saber qué puede ser modificado o mejorado dentro de la arquitectura del sistema.",
          },
          {
            title: "Pentesting de Caja Gris",
            text:
              "Puede definirse como la mezcla de los dos anteriores, el auditor posee cierta información a la hora de realizar el test, la suficiente para no partir de cero. Es el tipo de pentest más recomendado ya que se necesitará tiempo y medios para poder realizar este test de penetración en su totalidad.",
          },
          {
            title: "Pentesting de Caja Negra",
            text:
              "Es el tipo de pentesting más “real” ya que, el Pentester no tiene apenas datos sobre la organización y actúa como un ciberdelincuente más. Por eso, como si fuera una prueba “a ciegas” se debe descubrir las vulnerabilidades y amenazas en la estructura de la red.",
          },
        ],
        footer:
          "Estas interesado en saber más sobre nuestro servicio de Pentesting, contáctanos",
      },
    },
    courses: {
      hackingweb: {
        firstword: "The best ",
        keyword: "defense is a",
        secondword: "good offense",
        subtitle:
          "Aprende a proteger tus sistemas web con la gente más especializada en Hacking Web",
        img: "",
        titleDescription: "Curso de Hacking Web",
        description:
          "Con el curso de Hacking Web aprenderas a encontrar vulnerabilidades en cualquier sitio web, desde la recoleccion de informacion hasta reportar las vulnerabilidades encontradas",
        caracteristics: [
          {
            caracteristicTitle: "Introducción al Hacking Web",
            description: "Introducción al Hacking Web",
          },
          {
            caracteristicTitle: "Recolección de información",
            description: "Recolección de información",
          },
          { caracteristicTitle: "Ataques", description: "Ataques" },
          {
            caracteristicTitle: "Tu propio Laboratorio",
            description: "Tu propio Laboratorio",
          },
          {
            caracteristicTitle: "Hands On",
            description: "Hands On",
          },
        ],
      },
    },
    programDetail: {},
  },
  mutations: {
    setProgramDetail(state, content) {
      state.programDetail = content;
    },
  },
  actions: {
    getProgramDetail({ commit }, id) {
      return axios
        .get("https://jama.contreras.website/api/v1/programs/" + id)
        .then((r) => {
          commit("setProgramDetail", { content: r.data });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  modules: {},
});
