<template>
  <div class="header">
    <div class="header-container">
      <router-link class="logo" :to="{ path: '/' }">
        <img src="../assets/img/logo.png" alt="" />
      </router-link>
      <div class="nav">
        <div class="nav-header">
          <router-link :to="{}"
            >Servicios
            <ul class="submenu">
              <li>
                <router-link
                  class="submenu-option"
                  :to="{ path: '/services/pentesting' }"
                  >Pentesting</router-link
                >
              </li>
              <li>
                <router-link
                  class="submenu-option"
                  :to="{ path: '/services/bugbounty' }"
                  >BugBounty</router-link
                >
              </li>
            </ul>
          </router-link>
          <router-link :to="{}"
            >Cursos
            <ul class="submenu">
              <li>
                <router-link
                  class="submenu-option"
                  :to="{ path: '/courses/pentesting' }"
                  >Certificado de Pentesting</router-link
                >
              </li>
              <li>
                <router-link
                  class="submenu-option"
                  :to="{ path: '/courses/hackingweb' }"
                  >Curso Hacking Web</router-link
                >
              </li>
              <li>
                <router-link
                  class="submenu-option"
                  :to="{ path: '/courses/sql' }"
                  >Introduccion a SQL</router-link
                >
              </li>
            </ul>
          </router-link>
          <router-link :to="{ path: '/contact' }">Contacto</router-link>
          <router-link :to="{ path: '/about' }">Nosotros</router-link>
        </div>
        <div class="responsive-menu">
          <label for="check">
            <input @click="showOptions" type="checkbox" id="check" />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
      </div>
      <div class="signup">
        <Calltoaction
          :text="'Registrate'"
          :style="'calltoaction-gradient'"
          :whereto="'/jama-bugbounty'"
          :icon="true"
          :iconName="'flaticon-play'"
        />
        <div class="signup-options">
          <ul class="submenu">
            <li>
              <router-link class="submenu-option" :to="{ path: '/login' }"
                >Login</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-show="showMenu" class="responsive-options">
      <div class="options">
        <ul>
          <li @click="openServicesOption">
            Servicios
            <div></div>
            <div></div>
          </li>
          <ul v-show="servicesOptions" class="options-submenu">
            <li>
              <router-link :to="{ path: '/services/pentesting' }"
                >Pentesting</router-link
              >
            </li>
            <li>
              <router-link :to="{ path: '/services/bugbounty' }"
                >BugBounty</router-link
              >
            </li>
          </ul>
          <li @click="openCoursesOptions">
            Cursos
            <div></div>
            <div></div>
          </li>
          <ul v-show="coursesOptions" class="options-submenu">
            <li>
              <router-link
                class="submenu-option"
                :to="{ path: '/courses/pentesting' }"
                >Certificado de Pentesting</router-link
              >
            </li>
            <li>
              <router-link
                class="submenu-option"
                :to="{ path: '/courses/hackingweb' }"
                >Curso Hacking Web</router-link
              >
            </li>
            <li>
              <router-link class="submenu-option" :to="{ path: '/courses/sql' }"
                >Introduccion a SQL</router-link
              >
            </li>
          </ul>
          <li>
            <router-link class="submenu-link" :to="{ path: '/contact' }"
              >Contacto</router-link
            >
          </li>
          <li>
            <router-link class="submenu-link" :to="{ path: '/about' }"
              >Nosotros</router-link
            >
          </li>
          <li>
            <Calltoaction
              :text="'Registrate'"
              :style="'calltoaction-gradient'"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Calltoaction from "./calltoaction.vue";
export default {
  data() {
    return {
      showMenu: false,
      coursesOptions: false,
      servicesOptions: false,
    };
  },
  components: {
    Calltoaction,
  },
  methods: {
    showOptions() {
      this.showMenu = !this.showMenu;
    },
    openServicesOption() {
      this.servicesOptions = !this.servicesOptions;
    },
    openCoursesOptions() {
      this.coursesOptions = !this.coursesOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 1;
  box-shadow: 0px 4px 20px #00073633;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1280px;
    .signup {
      position: relative;
      .signup-options {
        .submenu {
          background: white;
          height: 0;
          left: 0;
          visibility: hidden;
          position: absolute;
          transition: all 0.5s ease;
          top: 100%;
          margin: 0;
          padding: 0;
          width: 100%;
          border-radius: 6px;
          box-shadow: 4px 4px 20px #00073633;
          li {
            padding: 0;
            margin: 0;
            text-align: left;
            padding: 10px;
            a {
              color: #314950;
              opacity: 0;
            }
            &:hover {
              background: #222ac3;
              .submenu-option {
                color: #0eebe1;
              }
            }
          }
        }
      }
      &:hover {
        .submenu {
          height: 100%;
          visibility: inherit;
          transform: translateY(0);
          .submenu-option {
            opacity: 1;
          }
        }
      }
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 220px;
        @media (max-width: 1280px) {
          width: 150px;
        }
      }
      padding: 0;
    }
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .nav-header {
        a {
          color: #314950;
          height: 100%;
          font-size: 15px;
          font-weight: 500;
          transition: transform ease-in-out 0.3s;
          position: relative;
          .submenu {
            background: white;
            height: 0;
            left: 0;
            visibility: hidden;
            position: absolute;
            transition: all 0.5s ease;
            top: 100%;
            margin: 0;
            padding: 0;
            width: 300px;
            border-radius: 6px;
            box-shadow: 4px 4px 20px #00073633;
            li {
              padding: 0;
              margin: 0;
              text-align: left;
              padding: 10px;
              &:hover {
                background: #222ac3;
                .submenu-option {
                  color: #0eebe1;
                }
              }
              .submenu-option {
                opacity: 0;
                padding: 0;
                margin: 0;
              }
            }
          }
          &:hover {
            .submenu {
              height: 150px;
              visibility: inherit;
              transform: translateY(0);
              .submenu-option {
                opacity: 1;
              }
            }
          }
        }
        @media (max-width: 1280px) {
          display: none;
        }
      }
      .responsive-menu {
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: none;
        position: relative;
        z-index: 1;
        label {
          padding: 20px;
          display: flex;
          flex-direction: column;
          width: 70px;
          cursor: pointer;
          span {
            background: black;
            border-radius: 10px;
            height: 3px;
            margin: 3px 0;
            transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
            &:nth-of-type(1) {
              width: 50%;
            }
            &:nth-of-type(2) {
              width: 100%;
            }
            &:nth-of-type(3) {
              width: 75%;
            }
          }
        }
        input[type="checkbox"] {
          display: none;
          &:checked {
            ~ {
              span {
                &:nth-of-type(1) {
                  transform-origin: bottom;
                  transform: rotatez(49deg) translate(7px, 3px);
                  height: 5px;
                  margin: 5px 0;
                }
                &:nth-of-type(2) {
                  transform-origin: top;
                  transform: rotatez(-45deg);
                  height: 5px;
                  margin: 5px 0;
                }
                &:nth-of-type(3) {
                  transform-origin: bottom;
                  width: 50%;
                  transform: translate(13px, -11px) rotatez(48deg);
                  height: 5px;
                  margin: 5px 0;
                }
              }
            }
          }
        }

        @media (max-width: 1280px) {
          display: block;
        }
      }
    }
    .signup {
      @media (max-width: 1280px) {
        display: none;
      }
    }
    @media (max-width: 1280px) {
      padding: 0 20px;
      width: 100%;
      min-width: 0;
    }
  }
  .responsive-options {
    position: fixed;
    background: white;
    height: 100vh;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      padding: 0;
      margin: 0;
      width: 100vw;
      li {
        width: 100%;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .submenu-link {
          color: black;
          padding: 0;
        }
        div {
          width: 10px;
          height: 2px;
          background: black;
          &:nth-child(1) {
            margin-left: 10px;
            transform: rotatez(49deg) translate(0px, -2px);
          }
          &:nth-child(2) {
            transform: rotatez(-49deg) translate(0px, -3px);
          }
        }
      }
      .options-submenu {
        background: #f9f9ff;
        li {
          a {
            color: black;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
