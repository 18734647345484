<template>
  <div class="swiper-cards">
    <div
      class="header"
      :style="
        'background: url(' +
          publicPath +
          content.image +
          ');' +
          'background-size:cover;'
      "
    ></div>
    <div class="content">
      <div class="content-header">
        <h1>{{ content.title }}</h1>
        <span>{{ content.subTitle }}</span>
      </div>
      <p>{{ content.content }}</p>
      <div class="content-footer">
        <calltoaction
          :whereto="'/contact'"
          :style="'calltoaction-openvideo'"
          :text="content.buttonText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Calltoaction from "./calltoaction.vue";
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    Calltoaction,
  },
  props: {
    content: {
      type: Object,
      required: true,
      default: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-cards {
  width: 440px;
  box-shadow: 0px 0px 30px #00073614;
  @media (max-width: 1280px) {
    width: 100%;
  }
  .header {
    border-radius: 6px 6px 0px 0px;
    height: 200px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 0px 0px 6px 6px;
    padding: 0 45px 50px 45px;
    height: 405px;
    .content-header {
      text-align: center;
      width: 100%;
      span {
        text-transform: uppercase;
      }
    }
    p {
      font-size: 15px;
    }
    .content-footer {
      margin: 25px 0 0 0;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
