<template>
  <div id="main-services" class="services">
    <div class="header">
      <h1>Nuestros Servicios</h1>
      <p>Seguridad informática</p>
    </div>
    <div class="swiper">
      <swiper
        :slides-per-view="slidesPerView"
        :space-between="40"
        :centered-slides="true"
        :navigation="{ nextEl: '.next-slide', prevEl: '.prev-slide' }"
      >
        <swiper-slide v-for="(slide, $s) in slides" :key="$s">
          <swiperCards :content="slide" />
        </swiper-slide>
        <div class="navigation">
          <button class="prev-slide"><i class="flaticon-up-arrow"></i></button>
          <button class="next-slide"><i class="flaticon-up-arrow"></i></button>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
//Import swiper core
import SwiperCore, { Navigation } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import swiperCards from "./swiper-cards";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);

export default {
  data() {
    return {
      swiperOption: {
        centeredSlides: true,
      },
      slides: [
        {
          image: "service-1.png",
          title: "Pentesting",
          subTitle: "Security Team",
          content:
            "Si tienes planeado analizar vulnerabilidades a tus sistemas, contamos con las personas más especializadas y capacitadas en el país. Análisis de vulnerabilidades desde sistemas informáticos, redes o perimetral.",
          route: "",
          buttonText: "Contáctanos",
        },
        {
          image: "service-2.png",
          title: "Bug Bounty",
          subTitle: "Plataforma",
          content:
            "Los entusiastas de seguridad informática encontraran errores o vulnerabilidades en tu sistema y a través de nuestra plataforma. Bug Bounty te ayuda a reportar vulnerabilidades antes que cualquier hacker con malas intenciones.",
          route: "",
          buttonText: "Contáctanos",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    swiperCards,
  },
  methods: {},
  computed: {
    slidesPerView() {
      if (window.innerWidth >= 1280) {
        return 3;
      } else {
        return 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.services {
  padding: 80px 0 0 0;
  .header {
    text-align: center;
    h1 {
      font-size: 40px;
      font-weight: 800;
      margin: 0;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      margin: 24px 0 0 0;
    }
  }
  .swiper {
    display: flex;
    align-items: center;
    justify-content: center;
    .swiper-container {
      width: 100%;
      position: relative;
      padding-bottom: 10px;
      .swiper-wrapper {
        .swiper-slide {
          margin: 170px 0 0 0;
          @media (max-width: 1280px) {
            padding: 20px;
          }
        }
      }
      .navigation {
        position: absolute;
        z-index: 2;
        top: 60px;
        right: 0;
        width: 100%;
        text-align: center;
        button {
          width: 125px;
          height: 50px;
          border-radius: 25px;
          margin: 0 15px;
          background-color: #222ac3;
          color: white;
          position: relative;
          border: none;
          i {
            right: 50px;
            position: absolute;
            top: 13px;
            &::before {
              margin: 0;
              font-size: 24px;
            }
          }
          &.prev-slide {
            i {
              transform: rotate(270deg);
            }
          }
          &.next-slide {
            i {
              transform: rotate(450deg);
            }
          }
          &.swiper-button-disabled {
            background-color: #a6a7c1;
            border: 1px solid #a6a7c1;
          }
        }
      }
    }
  }
}
</style>
