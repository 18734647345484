<template>
  <div id="main-courses" class="courses">
    <div class="header">
      <h1>Cursos</h1>
      <span>Capacitación</span>
      <p>
        Para todo aquel individuo deseoso de mantenerse capacitado en seguridad
        informática nuestro equipo de profesionales en el área podrá brindarte
        cursos de capacitación.
      </p>
    </div>
    <div class="courses-cards">
      <div
        class="cards"
        v-for="(course, $c) in courses"
        :key="$c"
        @click="goToCourse(course.path)"
      >
        <img src="../assets/img/course.svg" alt="" />
        <div class="content">
          <div class="title">
            <h1>{{ course.title }}</h1>
            <span>{{ `${course.hours}` }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "courses",
  data() {
    return {
      courses: [
        {
          title: "Certificado de Pentesting",
          hours: "Próximamente ",
          path: "pentesting",
        },
        { title: "Introduccion a SQL", hours: "Próximamente", path: "sql" },
        { title: "Curso Hacking Web", hours: "15 hrs ", path: "hackingweb" },
      ],
    };
  },
  methods: {
    goToCourse(value) {
      this.$router.push({ path: "/courses/" + value });
    },
  },
};
</script>

<style lang="scss" scoped>
.courses {
  margin: 140px 60px 190px 60px;
  min-width: 1280px;
  @media (max-width: 1280px) {
    min-width: 0;
    width: 100%;
  }
  .header {
    text-align: center;
    h1 {
      font-size: 40px;
      margin: 0 0 25px 0;
    }
    span {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }
    p {
      width: 765px;
      margin: 60px auto 100px auto;
      font-size: 15px;
      @media (max-width: 1280px) {
        margin: 30px auto;
        width: 100%;
      }
    }
  }
  .courses-cards {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    .cards {
      height: 150px;
      background-color: #222ac3;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 40px;
      cursor: pointer;
      .content {
        width: 175px;
        margin-left: 35px;
        h1 {
          color: #0eebe1;
          font-size: 24px;
          margin: 0 0 10px 0;
          line-height: 35px;
        }
        span {
          color: white;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    @media (max-width: 1280px) {
      padding: 0 60px;
      width: 100%;
      flex-direction: column;
      .cards {
        &:nth-child(2) {
          margin: 20px 0;
        }
      }
    }
  }
}
</style>
